<template>
  <!--
  <div class="table">
    <ComparisonTable
      :instance="instance"
      :rank="rank"
      :plot_data="table_data"
      :metrics="metrics"
      :labels="labels"
      :tools="tools"
    />
  </div>-->
  <div class="spider_container">
    <v-chart class="chart" :option="option" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { RadarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
//import ComparisonTable from "@/components/widgets/ComparisonTable.vue";
import getMetricData from "../../composables/getMetricData";
import getToolData from "../../composables/getToolData";
import invertMetric from "../../composables/invertMetric";
import { ref, watchEffect } from "@vue/runtime-core";

use([
  CanvasRenderer,
  RadarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  name: "Comparison",
  props: ["instance", "rank", "tools"],
  components: {
    VChart,
    //ComparisonTable,
  },
  setup(props) {
    const metrics = [
      "precision",
      "recall",
      "f1",
      "l2",
      "runtime_analysis",
      "memory_analysis",
    ];
    const labels = {
      "precision": "Precision",
      "recall": "Recall",
      "f1": "F1 Score",
      "l2": "Lowest L2 Error (Average ranking)",
      "runtime_analysis": "Runtime efficiency",
      "memory_analysis": "Memory efficiency"
    };

    const tool_data = ref({});
    const plot_data = ref({});
    const table_data = ref({});
    const option = ref({});

    const { metric_data, error, load_metric_data } = getMetricData(
      props.instance,
      metrics
    );

    load_metric_data();

    watchEffect(() => {
      for (let t in props.tools) {
        var toolname = props.tools[t];

        tool_data.value[toolname] = getToolData(
          metric_data.value,
          toolname,
          props.instance,
          props.rank
        );
        plot_data.value[toolname] = tool_data.value[toolname];
        table_data.value[toolname] = tool_data.value[toolname];
      }

      invertMetric(tool_data.value, props.tools, "runtime_analysis");
      invertMetric(tool_data.value, props.tools, "memory_analysis");
      invertMetric(tool_data.value, props.tools, "l2");

      option.value = {
        legend: {
          data: props.tools,
          left: 'left',
          top:0,
        },
        title: {
          text: 'The higher, the better',
          left: 'left',
          top: '10%',
        },
        radar: {
          center: ['50%', '50%'],
          shape: 'circle',
          axisName: {
            color: '#fff',
            backgroundColor: '#666',
            borderRadius: 3,
            padding: [3, 5]
          },  
          indicator: (function (metrics, labels, plot_data) {
            var indicators = [];
            for (let ii = 0; ii < metrics.length; ii++) {
              let metric = metrics[ii];
              let maxval = 0;
              for (let jj = 0; jj < props.tools.length; jj++) {
                let dataval = plot_data.value[props.tools[jj]][metric];
                if (dataval > maxval) {
                  maxval = dataval;
                }
              }
              indicators.push({
                name: labels[metric],
                max: maxval,
              });
            }
            return indicators;
          })(metrics, labels, plot_data),
        },
        series: (function (tools, plot_data, metrics) {
          var series = [];
          for (let t = 0; t < tools.length; t++) {
            let tool = tools[t];
            let tool_plot_data = [];
            for (let m = 0; m < metrics.length; m++) {
              let metric = metrics[m]
              tool_plot_data.push(plot_data.value[tool][metric])
            series.push({
              type: "radar",
              shape: "circle",
              emphasis: {
                areaStyle: {
                  color: "rgba(0,250,0,0.3)",
                },
              },
              data: [
                {
                  value: tool_plot_data,
                  name: tool,
                },
              ],
            });
            }
          }
          return series;
        })(props.tools, plot_data, metrics),
      };
    });

    return { plot_data, option, metrics, labels };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
.table {
  align-self: "center";
}
p {
  color: black;
}
.spider_container {
  height: 100%;
}
</style>
