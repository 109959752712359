<template>
  <v-chart class="chart" :option="option" />
  <div class="legend"><b>Runtime</b> necessary for <b>training</b> the model (min).</div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { DataZoomComponent } from 'echarts/components';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([
  CanvasRenderer,
  BarChart,
  DataZoomComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default defineComponent({
  name: "Runtime_training",
  props: ['instance', 'sample'],
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  created() {
    fetch(`data/${this.instance}.runtime_training.json`)
      .then(res => {
        const json_data=res.json();
        return json_data
        })
      .then(json_data => this.create_plot(json_data))
  },
  data () {
    return {
      option: {
        },
      json_data: {},
      plot_data: [],
      labels: []
    }
  },
  methods: {
    create_plot (json_data) {
        // keep the loaded data
        this.json_data=json_data
        // set the filtered data
        this.filtered_data=this.json_data
        this.plot_data = []
        this.labels = []
        for(var i in Object.keys(json_data.toolname)) {
          if (json_data.sample[i] == this.sample){
            this.plot_data.push(json_data.value[i]/60)
            this.labels.push(json_data.toolname[i])
          }
        }
        this.option = ref({
            yAxis: {
              type: 'category',
              data: this.labels
            },
            xAxis: {
                type: 'value',
            },
            dataZoom: [{
                    type: 'slider',
                    top: '0%',
                    height: 15
                }],
            grid: {
                right: '5%',
                bottom: '0%',
                top: '10%',
                left: '5%',
                containLabel: true
            },
            series: [{
                data: this.plot_data,
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                },
                color: 'brown'
            }]
        });
      }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
.legend {
  color: black;
  font-size:0.8em;
  padding-top:0.5em;
  margin-top:0.5em;
  text-align:left;
  border: 0 solid black;
  border-top-width: 1px;
}
</style>
