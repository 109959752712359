<template>
<div class="about">
LEMMI <i>A <b>L</b>ive <b>E</b>valuation of Computational <b>M</b>ethods for <b>M</b>etagenome <b>I</b>nvestigation</i>, is an online resource and pipeline dedicated to the continuous benchmarking of newly published methods related to metagenomic profiling or related analyses (here, amplicon sequencing).
<br/><br/>It uses a container-based approach and exploits the ability of many methods to build a reference database to compare them under identical conditions. By using publicly available data to generate benchmark samples, it is reactive and flexible, while implementing strategies to avoid overfitting.
<br/><br/>LEMMI provides a standalone pipeline for personal benchmarking and for adapting pipelines for the LEMMI online benchmarking. See the documentation and submission form above. You can also find compatible containers in the Evaluated Tools section.
<br/><br/>If you benefit from LEMMI, cite: <a target="_blank" href="https://genome.cshlp.org/content/early/2020/07/01/gr.260398.119">https://genome.cshlp.org/content/early/2020/07/01/gr.260398.119</a>, which describes the <a target="_blank" href="https://lemmi-v1.ezlab.org">initial version</a> of LEMMI.
<br/><br/>
Our lab: <a target="_blank" href="https://www.ezlab.org/">https://www.ezlab.org/</a>
<br/><br/>
<div class="twitter">
<img src="img/masto.png"/><a href="https://tooting.ch/@matsp" target="_blank">https://tooting.ch/@matsp</a>
</div>
<div class="spacer"></div>
</div>
<Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'About',
  components: {
    Footer
  }
}
</script>

<style scoped>
img {
  margin: 0 1em 0 0;
  padding: 0;
  width: 1.5em;
}
.twitter {
    display: flex;
    align-items: center;
}
.twitter a:visited {
    color: #1DA1F2;
    font-weight: 900;
}
.twitter {
    color: #1DA1F2;
    font-weight: 900;
}
.about {
    font-family: "Arial", sans-serif;
    border: 1px solid #EEE;
    display: block;
    padding: 1em;
    text-align: left;
}

.spacer {
  clear:both;
  height:2.2em;
}


</style>
