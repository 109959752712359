<template>
    <div>
    <a :id="tool" class="anchor"></a>
    <h1>{{ tool }}</h1>
    <table v-for="details, version in content">
        <tr><th>Version:</th><th>{{ version }}</th></tr>
        <tr><th>Name:</th><th>{{ details.name }}</th></tr>
        <tr><td>LEMMI container source:</td><td><a :href="details.container_source" target="_blank">{{details.container_source}}</a></td></tr>
        <tr><td>LEMMI container for Docker:</td><td><textarea rows="1" cols="100">docker pull {{details.repository}}</textarea></td></tr>
        <tr><td>LEMMI container for Apptainer (Singularity):</td><td><textarea rows="1" cols="100">singularity pull {{tool.toLowerCase()}}.sif docker://{{details.repository}}</textarea></td></tr>
        <tr><td>Tool website/Git:</td><td><a :href="details.website" target="_blank">{{details.website}}</a></td></tr>
        <tr><td>Publication:</td><td><a :href="details.publication" target="_blank">{{ details.publication }}</a></td></tr>
        <tr><td>Comments:</td><td>{{ details.comments }}</td></tr>
        <tr><td>Included in these benchmarks:</td><td>{{ details.included }}</td></tr>
    </table>
    </div>
</template>

<script>

export default {
  name: 'ToolSummary',
  props: {
    tool: String,
    content: Object,
  },
  data() {
    return {
      box_content: {
        "text": ""
      },
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

table {
    text-align:left;
    width:100%;
    border: 1px solid grey;
    padding:1em;
}

h1 {
    width:100%;
    background-color:lightgrey;
}

.anchor {
   position: relative;
   top: -10em;
}

</style>
